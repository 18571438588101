import { CardElement, Elements, useRecurly } from '@recurly/react-recurly';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { ReactComponent as GenericCreditCardIcon } from '../../../../assets/images/icons/icon_credit_card_outline.svg';
import ActionContainer from '../../../../components/ActionContainer';
import Button from '../../../../components/Button';
import FiRecurlyProvider from '../../../../components/FiRecurlyProvider';
import ErrorMessages, { UserFacingError, getCustomerMessageFromApolloError } from '../../../../lib/errors';
import formStyles from '../../../../styles/form.module.scss';
import * as types from '../../../../types';
import { gqlTypes } from '../../../../types';
import BillingAddress, { AddressErrorFields } from './BillingAddress';
import styles from './EditCreditCardBillingInfo.module.scss';

export type UpdateCreditCardBillingInfoFunction = (options: {
  input: gqlTypes.BillingAccountInput;
  firstName: string;
  lastName: string;
}) => Promise<void>;

interface IEditCreditCardBillingInfoProps {
  shippingAddress?: types.Address;
  updateBillingInfo: UpdateCreditCardBillingInfoFunction;
  billingInfo?: types.BillingInfo | null;
  actionText?: string;
  submitting: boolean;
  setError: (message: string) => void;
  clearError: () => void;
  onCancelEdit?: () => void;
  compactForm?: boolean;
  actionNote?: React.ReactNode;
  additionalActions?: React.ReactNode;
  outlineErrorFields?: boolean; // If enabled, a red outline will be shown on fields with errors
}

function formatCreditCardNumber(lastFourDigits: number): string {
  return `• • • • ${lastFourDigits.toString()}`;
}

function CreditCardForm({
  shippingAddress,
  updateBillingInfo,
  billingInfo,
  actionText,
  submitting: parentSubmitting,
  setError,
  clearError,
  onCancelEdit,
  compactForm,
  actionNote,
  additionalActions,
  outlineErrorFields = false,
}: IEditCreditCardBillingInfoProps) {
  const recurly = useRecurly();
  const formRef = React.createRef<HTMLFormElement>();

  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [addressErrorFields, setAddressErrorFields] = useState<AddressErrorFields>({});
  const [cardInfoError, setCardInfoError] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>(billingInfo?.firstName ?? '');
  const [lastName, setLastName] = useState<string>(billingInfo?.lastName ?? '');

  const [tokenSubmitting, setTokenSubmitting] = useState(false);
  const submitting = tokenSubmitting || parentSubmitting;

  const handleAddPaymentDetails: React.FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault();

      if (submitting || !formRef.current) {
        return;
      }

      clearError();
      setFirstNameError(false);
      setLastNameError(false);
      setTokenSubmitting(true);

      recurly.token(formRef.current, (recurlyError, token) => {
        if (recurlyError) {
          const messages = [];
          const addressErrors: AddressErrorFields = {};
          if (recurlyError.fields?.includes('first_name')) {
            setFirstNameError(true);
          }
          if (recurlyError.fields?.includes('last_name')) {
            setLastNameError(true);
          }

          if (recurlyError.fields?.includes('first_name') || recurlyError.fields?.includes('last_name')) {
            messages.push(`Please enter a valid first and last name`);
          }

          if (recurlyError.fields?.includes('number')) {
            messages.push(`Invalid credit card number`);
            setCardInfoError(true);
          }

          if (recurlyError.fields?.includes('month') || recurlyError.fields?.includes('year')) {
            messages.push(`Invalid expiration date`);
            setCardInfoError(true);
          }

          if (recurlyError.fields?.includes('cvv')) {
            messages.push(`Invalid CVV`);
            setCardInfoError(true);
          }

          if (recurlyError.fields?.includes('address1')) {
            addressErrors.street1 = true;
          }

          if (recurlyError.fields?.includes('city')) {
            addressErrors.city = true;
          }

          if (recurlyError.fields?.includes('state')) {
            addressErrors.state = true;
          }

          if (recurlyError.fields?.includes('postal_code')) {
            addressErrors.postal_code = true;
          }

          setAddressErrorFields(addressErrors);

          if (messages.length === 0) {
            messages.push(recurlyError.message);
          }

          setError(messages.join(', '));
        } else {
          updateBillingInfo({
            input: { billingInfo: { token: token.id } },
            firstName,
            lastName,
          }).catch((err) => {
            const message =
              err instanceof UserFacingError
                ? err.message
                : getCustomerMessageFromApolloError(err) || ErrorMessages.INPUT_ERROR;

            setError(message);
          });
        }

        setTokenSubmitting(false);
      });
    },
    [recurly, updateBillingInfo, submitting, clearError, setError, formRef, firstName, lastName],
  );

  return (
    <form
      className={classNames(styles.form, {
        [styles.compact]: compactForm,
      })}
      onSubmit={handleAddPaymentDetails}
      ref={formRef}
    >
      <div className={styles.formInputContainer}>
        {billingInfo && billingInfo.paymentInfo.__typename === 'ObfuscatedCardInfo' && (
          <div className={styles.formSection}>
            <div className={styles.cardOnFileInfo}>
              <GenericCreditCardIcon className={styles.creditCardIcon} />
              <strong>
                {billingInfo.paymentInfo.type} {formatCreditCardNumber(billingInfo.paymentInfo.lastFour)}
              </strong>
              currently on file
            </div>
          </div>
        )}
        <div className={styles.formSection}>
          {!compactForm && <h4>Credit card</h4>}
          <input type="hidden" name="recurly-token" data-recurly="token" />
          <div className={styles.formRow}>
            <input
              type="text"
              placeholder="First name"
              autoComplete="cc-given-name"
              data-recurly="first_name"
              onChange={(event) => setFirstName(event.target.value)}
              value={firstName}
              className={outlineErrorFields && firstNameError ? formStyles.error : ''}
              data-hj-whitelist
            />
            <input
              type="text"
              placeholder="Last name"
              autoComplete="cc-family-name"
              data-recurly="last_name"
              className={outlineErrorFields && lastNameError ? formStyles.error : ''}
              onChange={(event) => setLastName(event.target.value)}
              value={lastName}
              data-hj-whitelist
            />
          </div>
          {/* This Recurly element is a complex set of inputs that can't easily be styled outside what they expose */}
          <CardElement style={cardInfoError ? { placeholder: { color: '#ff004f' } } : {}} />
        </div>
        <div className={styles.formSection}>
          <BillingAddress
            shippingAddress={shippingAddress}
            billingInfo={billingInfo}
            compactForm={compactForm}
            errorFields={addressErrorFields}
          />
        </div>
      </div>
      <div className={styles.formActionContainer}>
        {actionNote}
        <ActionContainer>
          {onCancelEdit && (
            <Button secondary disabled={submitting} onClick={onCancelEdit}>
              Cancel
            </Button>
          )}
          <Button disabled={submitting} loading={submitting} type="submit">
            {actionText || 'Save and exit'}
          </Button>
          {additionalActions}
        </ActionContainer>
      </div>
    </form>
  );
}

export default function EditCreditCardBillingInfo(props: IEditCreditCardBillingInfoProps) {
  return (
    <FiRecurlyProvider>
      <Elements>
        <CreditCardForm {...props} />
      </Elements>
    </FiRecurlyProvider>
  );
}
