import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import styles from '../styles/ManageActions.module.scss';
import { supplementsManagement as events } from '../../../../lib/analytics/events';
import { ReactComponent as UpdateIcon } from '../../../../assets/images/icons/supplements_management/icon_update_clock.svg';
import { ReactComponent as HouseIcon } from '../../../../assets/images/icons/supplements_management/icon_house.svg';
import { ReactComponent as Arrow } from '../../../../assets/images/icons/arrow.svg';
import { BillingSubscription, SubscriptionOption } from '../SplashPage';
import * as types from '../../../../types';
import { useMutation } from '@apollo/client';
import AppPaths from '../../../../AppPaths';
import {
  updateSubscriptionMutation,
  supplementsSubscriptionsQuery,
  setSupplementShippingAddressMutation,
} from '../../../../graphql-operations';
import classNames from 'classnames';
import ErrorMessage, { useError } from '../../../../components/ErrorMessage';
import { getLoadingOrErrorElement } from '../../../../lib/util';
import Loading from '../../../../components/Loading';
import { logInternalError } from '../../../../lib/errors';
import UpdateShippingAddressModal, { AddressFields } from './UpdateShippingAddressModal';
import UpdateFrequencyModal from './UpdateFrequencyModal/UpdateFrequencyModal';

interface ManageSubscriptionActionsProps {
  hasMultipleSubscriptions: boolean; // All active supplement subscriptions.
  subscription: BillingSubscription; // Subscription the user is managing.
  currentSubscriptionOption: SubscriptionOption; // Plan attached to the subscription the user is managing.
  subscriptionOptions: types.ISupplementSubscriptionProduct[];
}

export default function ManageSubscriptionActions({
  hasMultipleSubscriptions,
  subscription,
  currentSubscriptionOption,
  subscriptionOptions,
}: ManageSubscriptionActionsProps) {
  const { error, errorID, setError } = useError();
  const history = useHistory();
  const [selectedSku, setSelectedSku] = useState('');
  const [updateMultiple, setUpdateMultiple] = useState(false);

  const [updateMutation, updateMutationState] = useMutation<
    types.gqlTypes.ECOMMERCE_updateSupplementSubscription,
    types.gqlTypes.ECOMMERCE_updateSupplementSubscriptionVariables
  >(updateSubscriptionMutation, {
    refetchQueries: [{ query: supplementsSubscriptionsQuery }],
    onCompleted: (data) => {
      const success = !!data.updateSupplementSubscription?.success;
      if (success) {
        history.push({
          pathname: AppPaths.Supplements.Root,
          state: { toast: { success: true, change: 'updated' }, refetch: false },
          search: window.location.search,
        });
        events.shipmentFrequencyUpdated({
          subscriptionId: subscription.id,
          oldSku: currentSubscriptionOption.sku,
          newSku: selectedSku,
        });
      }
      setError(`Failed to update subscription: ${data.updateSupplementSubscription?.reasonForFailure}`);
    },
    onError(err) {
      setError(err.message);
      logInternalError(err);
    },
  });

  const [setShippingAddressMutation, setShippingAddressMutationState] = useMutation<
    types.gqlTypes.ECOMMERCE_setSupplementShippingAddress,
    types.gqlTypes.ECOMMERCE_setSupplementShippingAddressVariables
  >(setSupplementShippingAddressMutation, {
    refetchQueries: [{ query: supplementsSubscriptionsQuery }],
    onCompleted: (data) => {
      const success = !!data.setSupplementShippingAddress?.success;
      if (success) {
        history.push({
          pathname: AppPaths.Supplements.Root,
          state: {
            toast: { success: true, change: 'updated', multipleSubscriptionsChanged: updateMultiple },
            refetch: false,
          },
          search: window.location.search,
        });
      }
      setError(`Failed to update shipping address: ${data.setSupplementShippingAddress?.reasonForFailure}`);
    },
    onError(err) {
      setError(err.message);
      logInternalError(err);
    },
  });

  const updateLoading = getLoadingOrErrorElement(updateMutationState.loading, null);
  const setShippingAddressLoading = getLoadingOrErrorElement(setShippingAddressMutationState.loading, null);

  const handleUpdate = (sku: string) => {
    setSelectedSku(sku);
    updateMutation({
      variables: {
        input: {
          newSku: sku,
          recurlySubscriptionId: subscription.id,
        },
      },
    });
  };

  const onUpdateAllSubscriptions = (shippingAddress: AddressFields) => {
    events.updateAllSubscriptionAddresses({ subscriptionId: subscription.id });
    setUpdateMultiple(true);
    setShippingAddressMutation({
      variables: {
        input: {
          recurlySubscriptionId: subscription.id,
          shippingAddress: shippingAddress,
          applyToAll: true,
        },
      },
    });
  };

  const onUpdateThisSubscription = (shippingAddress: AddressFields) => {
    events.updateJustThisSubscriptionsAddress({ subscriptionId: subscription.id });
    setUpdateMultiple(false);
    setShippingAddressMutation({
      variables: {
        input: {
          recurlySubscriptionId: subscription.id,
          shippingAddress: shippingAddress,
          applyToAll: false,
        },
      },
    });
  };

  const addressText = () => {
    const { street1, street2, city, state, zipcode } = subscription.address || {};
    if (street2) {
      return `${street1} ${street2}, ${city}, ${state} ${zipcode}`;
    }

    if (street1) {
      return `${street1}, ${city}, ${state} ${zipcode}`;
    }

    return '';
  };

  return (
    <>
      <div className={styles.manageActionsContainer}>
        {updateLoading || setShippingAddressLoading ? (
          <Loading className={styles.loading} />
        ) : (
          <>
            <div className={styles.options}>
              <UpdateShippingAddressModal
                trigger={
                  <div className={classNames(styles.option, styles.hasArrow)}>
                    <div className={styles.block}>
                      <div className={styles.icon}>
                        <HouseIcon />
                      </div>
                      <div className={styles.text}>
                        <div className={styles.title}>Update shipment address</div>
                        {addressText() && <div className={styles.subtitle}>{addressText()}</div>}
                      </div>
                    </div>
                    <div className={styles.actionArrow}>
                      <Arrow />
                    </div>
                  </div>
                }
                hasMultipleSubscriptions={hasMultipleSubscriptions}
                subscription={subscription}
                onUpdateAllSubscriptions={onUpdateAllSubscriptions}
                onUpdateThisSubscription={onUpdateThisSubscription}
              ></UpdateShippingAddressModal>
              <UpdateFrequencyModal
                trigger={
                  <div className={classNames(styles.option, styles.hasArrow)}>
                    <div className={styles.block}>
                      <div className={styles.icon}>
                        <UpdateIcon />
                      </div>
                      <div className={styles.text}>
                        <div className={styles.title}>Shipment frequency</div>
                        <div className={styles.subtitle}>
                          Ships every {currentSubscriptionOption.weeksBetweenShipments} weeks
                        </div>
                      </div>
                    </div>
                    <div className={styles.actionArrow}>
                      <Arrow />
                    </div>
                  </div>
                }
                onContinue={(frequency: number) => {
                  handleUpdate(currentSubscriptionOption.sku.replace(/(\d+)-week/, `${frequency}-week`));
                }}
                initialFrequency={currentSubscriptionOption.weeksBetweenShipments!}
              />
              {error && <ErrorMessage errors={[error]} errorID={errorID} />}
            </div>
          </>
        )}
      </div>
    </>
  );
}
