import AppPaths from '../../../AppPaths';
import SentryRoute from '../../../SentryRoute';
import React from 'react';
import { Switch } from 'react-router';
import SplashPage from './SplashPage';
import ManageSubscription from './ManageSubscription';
import Survey from './Survey';
import AddPayment from './PurchaseSelection/AddPayment';
import SkipDeterrent from './SkipDeterrent';
import ChangeFrequencyDeterrent from './ChangeFrequencyDeterrent';
import DiscountDeterrent from './DiscountDeterrent';
import { CheckoutContextProvider } from '../../../lib/CheckoutContext';
import CheckoutAddPayment from './PurchaseSelection/CheckoutAddPayment';

export default function Supplements() {
  return (
    <CheckoutContextProvider>
      <Switch>
        <SentryRoute exact path={AppPaths.Supplements.Root} component={SplashPage} />
        <SentryRoute exact path={AppPaths.Supplements.Manage()} component={ManageSubscription} />
        <SentryRoute exact path={AppPaths.Supplements.Survey()} component={Survey} />
        <SentryRoute exact path={AppPaths.Supplements.SkipShipment()} component={SkipDeterrent} />
        <SentryRoute exact path={AppPaths.Supplements.ChangeFrequency()} component={ChangeFrequencyDeterrent} />
        <SentryRoute exact path={AppPaths.Supplements.Discount()} component={DiscountDeterrent} />
        <SentryRoute exact path={AppPaths.Supplements.AddPayment} component={AddPayment} />
        <SentryRoute exact path={AppPaths.Supplements.CheckoutAddPayment} component={CheckoutAddPayment} />
      </Switch>
    </CheckoutContextProvider>
  );
}
