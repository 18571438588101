import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import * as types from '../../../../../types';
import styles from './ProductInfoV2.module.scss';
import HideChatWidget from '../../../../../components/ZendeskChat/HideChatWidget';
import ImageGallery from '../../../../../components/ImageGallery/ImageGallery';
import {
  getCartSKUs,
  getSKUForCartItem,
  PurchaseContainer,
  PurchaseType,
  SKUType,
  SUPPLEMENT_KEY_BENEFITS,
} from '../../../../ManageSubscription/Supplements/components/UpsellV2/UpsellV2';
import { formattedCentsToDollars, generateID } from '../../../../../lib/util';
import {
  addMultipleSupplementSubscriptionsMutation,
  supplementsSubscriptionsQuery,
} from '../../../../../graphql-operations';
import useCheckoutPaths from '../../../../../hooks/useCheckoutPaths';
import * as events from '../../../../../lib/analytics/events';
import { ReactComponent as CheckIcon } from '../../../../../assets/images/icons/check.svg';
import { ReactComponent as InfoIcon } from '../../../../../assets/images/icons/icon_info.svg';
import { ReactComponent as SpikeIcon } from '../../../../../assets/images/icons/icon_spike.svg';
import { ReactComponent as FiLogo } from '../../../../../assets/images/fi-logo-yellow-bg.svg';
import InAppCheckoutModal from '../../../../ManageSubscription/Supplements/components/InAppCheckoutModal/InAppCheckoutModal';
import { getFiGrowthBook } from '../../../../../lib/growthbook';

export interface ProductInfoV2Props {
  originalCart: types.Cart;
  originalCartPricing: types.CartPricing;
  originalOrderId: string;
}

export default function ProductInfoV2({ originalCart, originalCartPricing, originalOrderId }: ProductInfoV2Props) {
  const [currentCarouselImage, setCurrentCarouselImage] = useState<number>(0);
  const selectedSKUType = SKUType.Formula;
  const purchaseType = PurchaseType.Autoship;
  const [autoshipFrequency, setAutoshipFrequency] = useState<number>(6);
  const [cartModalOpen, setCartModalOpen] = useState<boolean>(false);
  const history = useHistory();
  const checkoutPaths = useCheckoutPaths();
  // Check feature flag value of supplements upsell test that adds notification imagery overlapping carousel,
  // changes the copy above the carousel, and adds highlighted promotion on checkout confirmation.
  const supplementsUpsellTestEnabled = getFiGrowthBook().getFeatureValue<boolean>('supplements-upsell-test', false);
  const goToThankYouPage = useCallback(
    (orderCart: types.Cart, invoiceNumber: string, cartPricing: types.CartPricing) => {
      history.replace(checkoutPaths.ThankYou, {
        orderId: invoiceNumber,
        orderedCart: orderCart,
        orderedCartPricing: cartPricing,
      });
    },
    [checkoutPaths.ThankYou, history],
  );

  const [purchaseMutation] = useMutation<
    types.gqlTypes.ECOMMERCE_addMultipleSupplementSubscriptions,
    types.gqlTypes.ECOMMERCE_addMultipleSupplementSubscriptionsVariables
  >(addMultipleSupplementSubscriptionsMutation, {
    refetchQueries: [{ query: supplementsSubscriptionsQuery }],
    onCompleted: (mutationData) => {
      const updatedCart = {
        ...originalCart,
        cartItems: {
          ...originalCart.cartItems,
          [generateID()]: {
            cartItemId: generateID(),
            lineItem: {
              lineItemId: generateID(),
              sku: getSKUForCartItem({
                skuType: selectedSKUType,
                purchaseType: purchaseType,
                eligibleForDiscount: true,
                frequency: autoshipFrequency,
              }),
            },
            quantity: 1,
          },
        },
      };
      supplementsUpsellTestEnabled
        ? events.supplements.upsellV2.experiment.confirm()
        : events.supplements.upsellV2.confirm();
      goToThankYouPage(updatedCart, originalOrderId, originalCartPricing);
    },
  });

  useEffect(() => {
    events.supplements.upsellV2.pageLoad();
  }, []);

  useEffect(() => {
    if (purchaseType === PurchaseType.Autoship) {
      events.supplements.upsellV2.autoshipOptionClicked();
    } else if (purchaseType === PurchaseType.OneTime) {
      events.supplements.upsellV2.buyOnceOptionClicked();
    }
  }, [purchaseType]);

  const onNotNow = () => {
    supplementsUpsellTestEnabled
      ? events.supplements.upsellV2.experiment.noThanks()
      : events.supplements.upsellV2.notNow();
    goToThankYouPage(originalCart, originalOrderId, originalCartPricing);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.newLabel}>NEW</div>
        <h1 className={styles.supplementsText}>FI SUPPLEMENTS</h1>
        <p className={styles.pitch}>
          {supplementsUpsellTestEnabled
            ? "Support your dog's health and track the results with Fi's powerful insights."
            : 'You can now easily add our new 8-in-1 supplements to your Fi Membership.'}
          <strong>
            <br />
            <br />
            <span className={classNames(styles.specialOffer)}>
              SPECIAL OFFER <span className={styles.specialOfferValue}>– 40% OFF YOUR FIRST BAG</span>
            </span>
          </strong>
        </p>
        <div
          className={classNames(
            styles.imageGalleryContainer,
            { [styles.hasNotification]: supplementsUpsellTestEnabled },
            { 'ecom-flow-revamp-light': currentCarouselImage === 2 },
          )}
        >
          {supplementsUpsellTestEnabled && (
            <div className={styles.notification}>
              <span className={styles.logo}>
                <FiLogo />
              </span>
              <div>
                <div className={styles.title}>
                  <SpikeIcon />
                  <p>Activity Increase</p>
                </div>
                <div className={styles.description}>
                  <p>Your dog's activity level has increased 17% in the past week.</p>
                </div>
              </div>
            </div>
          )}
          <ImageGallery
            media={[
              {
                type: 'image',
                url: `/product_images/supplements/upsell-v2/formula-bag-large.png`,
                retinaWidth: 345,
                retinaHeight: 258,
              },
            ]}
            showMobileGallerySelector={false}
            wrapAround
            squareAspectRatio
            onChange={(index: number) => setCurrentCarouselImage(index)}
          />
        </div>
        <div className={styles.skuOptionDetails}>
          {SUPPLEMENT_KEY_BENEFITS[selectedSKUType].map((benefit, index) => (
            <div key={index} className={styles.skuOptionKeyBenefitItem}>
              <div className={styles.skuOptionKeyBenefitItemImageContainer}>{benefit.image}</div>
              <div className={styles.skuOptionKeyBenefitItemText}>
                <div className={styles.skuOptionKeyBenefitItemTitle}>{benefit.title}</div>
                <div className={styles.skuOptionKeyBenefitItemDescription}>{benefit.description}</div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.testimonial}>
          <div className={styles.testimonialText}>70%</div>
          <div className={styles.testimonialDescription}>
            of Fi members who purchased supplements{' '}
            <span className={styles.testimonialDescriptionHighlight}>regret not starting sooner.</span>
          </div>
        </div>
        <PurchaseContainer
          purchaseType={purchaseType}
          autoshipFrequency={autoshipFrequency}
          setAutoshipFrequency={setAutoshipFrequency}
          isNextItemEligibleForFirstBagDiscount={true}
          setPurchaseType={() => {}}
          autoshipOnly
        />
        <HideChatWidget />
        <div className={styles.termsAndConditionsContainer}>
          <div className={styles.termsAndConditionsIcon}>
            <InfoIcon />
          </div>
          <div className={styles.termsAndConditionsTitle}>
            By clicking place order you agree to our{' '}
            <a href="https://tryfi.com/tos" target="_blank" rel="noopener noreferrer">
              terms and conditions.
            </a>
          </div>
        </div>
        <div className={styles.recurringSubscriptionContainer}>
          <div className={styles.recurringSubscriptionIcon}>
            <CheckIcon />
          </div>
          <div className={styles.recurringSubscriptionTitle}>
            Recurring subscription will start with shipment of 2nd bag for $25 / bag
          </div>
        </div>
      </div>
      <div className={styles.mobileCTAContainer}>
        <div className={styles.mobileCTAButtons}>
          <div className={styles.inAppCheckoutContainer}>
            <InAppCheckoutModal
              isUpsellExperiment={supplementsUpsellTestEnabled}
              open={cartModalOpen}
              onOpen={() => {
                supplementsUpsellTestEnabled
                  ? events.supplements.upsellV2.experiment.tryItWeeklyOptionClicked()
                  : events.supplements.upsellV2.tryItWeeklyOptionClicked();
              }}
              onClose={() => setCartModalOpen(false)}
              cartItems={[
                {
                  skuType: selectedSKUType,
                  purchaseType: purchaseType,
                  eligibleForDiscount: true,
                  frequency: autoshipFrequency,
                },
              ]}
              trigger={
                <div className={styles.addToCartContainer}>
                  <button className={styles.confirmOrderButton}>
                    {supplementsUpsellTestEnabled
                      ? 'Try it'
                      : `Try it ${formattedCentsToDollars(1500 / autoshipFrequency)}/wk`}
                  </button>
                </div>
              }
              onPurchase={() => {
                purchaseMutation({
                  variables: {
                    input: {
                      skusToAdd: getCartSKUs([
                        {
                          skuType: selectedSKUType,
                          purchaseType: purchaseType,
                          eligibleForDiscount: true,
                          frequency: autoshipFrequency,
                        },
                      ]),
                      forceApplyDiscount: true,
                    },
                  },
                });
              }}
              isUpsellLayout
            />
          </div>
          <button className={styles.notNowButton} onClick={() => onNotNow()}>
            No, thanks
          </button>
        </div>
        <div className={styles.mobileCTAFooter}>
          You will be charged {formattedCentsToDollars(1500)} today and will renew at{' '}
          {formattedCentsToDollars(2500 / autoshipFrequency)}/week billed as {formattedCentsToDollars(2500)} every{' '}
          {autoshipFrequency} weeks.
        </div>
      </div>
    </>
  );
}
