import React, { useState } from 'react';
import { gqlTypes } from '../../../../types';
import * as types from '../../../../types';
import EditCreditCardBillingInfo from './EditCreditCardBillingInfo';
import ViewBillingInfo from './ViewBillingInfo';

export type UpdateBillingInfoFunction = (args: { input: gqlTypes.BillingAccountInput }) => Promise<void>;

interface IBillingInfoProps {
  updateBillingInfo: UpdateBillingInfoFunction;
  handleNoChanges(): void;
  billingInfo: types.BillingInfo | null;
  shippingAddress: types.Address | undefined;
  submitting: boolean;
  startInEditMode?: boolean;
  setError: (message: string) => void;
  clearError: () => void;
  onCancelEditMode?: () => void;
  compactForm?: boolean;
  actionText?: string;
  includeCancel?: boolean;
  outlineErrorFields?: boolean;
  additionalActions?: React.ReactNode;
}

export default function BillingInfo({
  updateBillingInfo,
  handleNoChanges,
  billingInfo,
  shippingAddress,
  submitting,
  startInEditMode,
  setError,
  clearError,
  onCancelEditMode,
  compactForm,
  actionText,
  includeCancel = true,
  outlineErrorFields,
  additionalActions,
}: IBillingInfoProps) {
  const [isEditing, setIsEditing] = useState(billingInfo === null || startInEditMode);

  const onEdit = () => setIsEditing(true);

  // If there is no existing billing info, they are forced into edit mode and should not be able to cancel.
  const onCancelEdit =
    billingInfo && includeCancel
      ? () => {
          clearError();

          if (onCancelEditMode) {
            onCancelEditMode();
          } else {
            setIsEditing(false);
          }
        }
      : undefined;

  return (
    <div>
      {!isEditing && billingInfo ? (
        <ViewBillingInfo billingInfo={billingInfo} onEdit={onEdit} onContinue={handleNoChanges} />
      ) : (
        <EditCreditCardBillingInfo
          shippingAddress={shippingAddress}
          updateBillingInfo={updateBillingInfo}
          billingInfo={billingInfo}
          submitting={submitting}
          setError={setError}
          clearError={clearError}
          onCancelEdit={onCancelEdit}
          compactForm={compactForm}
          actionText={actionText}
          outlineErrorFields={outlineErrorFields}
          additionalActions={additionalActions}
        />
      )}
    </div>
  );
}
