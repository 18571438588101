import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { gqlTypes } from '../../../types';
import Button from '../../../components/Button/Button';
import { ReactComponent as Checkmark } from '../../../assets/images/checkmark_white.svg';
import styles from '../styles/SubscriptionContainer.module.scss';
import SubscriptionAppBar from '../components/SubscriptionAppBar';
import { supplementsManagement as events } from '../../../lib/analytics/events';
import SupplementPill from './components/SupplementPill';
import { supplementsSubscriptionsQuery } from '../../../graphql-operations';
import { BillingSubscriptionState, ShippingAddress, WeightRange } from '../../../types/gql-op-types';
import Upsell from './components/Upsell/Upsell';
import { FeaturesReady } from '@growthbook/growthbook-react';
import Loading from '../../../components/Loading';
import classNames from 'classnames';
import { ReactComponent as AddIcon } from '../../../assets/images/icons/add.svg';
import UpsellV2, { SKUType } from './components/UpsellV2/UpsellV2';
import { getFiGrowthBook } from '../../../lib/growthbook';

export interface SubscriptionOption {
  sku: string;
  name?: string;
  description?: string;
  priceInCents: number;
  discountedFromOriginalPriceInCents?: number;
  displayedRate?: string;
  displayedRateUnit: string;
  billingPeriodsPerTerm: number;
  weeksBetweenShipments?: number | null;
  inAppManagementName: string;
  freeTrialDays?: number | null;
  recommendedForDogWeightRangePounds?: WeightRange | null;
}

interface ShipmentDates {
  latestSkipDate: string;
  nextShipmentDate: string;
  displayDate: string;
  previewResumingShipmentDate: string;
  previewSkippingShipmentDate: string;
  previewUpdate: { sku: string; nextShipDate: string }[];
}
interface NextSupplementShipmentDetails {
  status: string | null;
  deliveryExpected?: string | null;
  trackingLink?: string | null;
  discount?: number | null;
}
export interface BillingSubscription {
  id: string;
  state: BillingSubscriptionState;
  subscriptionOption: SubscriptionOption;
  currentTermEndsAt: string;
  supplementShipmentDates?: ShipmentDates | null;
  nextSupplementShipmentDetails?: NextSupplementShipmentDetails | null;
  address?: ShippingAddress | null;
}

export type ChangeType = 'canceled' | 'updated' | 'added';

export const SUPPLEMENT_PREFIXES = ['F1-V-', 'F2-V-'];

const skuPrefixesForType = {
  [SKUType.Formula]: ['f1-v-mv-180', 'f2-v-mv-180'],
  [SKUType.Calming]: ['f1-v-cc-180'],
  [SKUType.HipJoint]: ['f1-v-hj-180'],
  [SKUType.SkinCoat]: ['f1-v-sc-180'],
};

interface ToastProps {
  change: ChangeType;
  multipleSubscriptionsChanged: boolean;
}

export function Toast({ change, multipleSubscriptionsChanged }: ToastProps) {
  const [shown, setShown] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setShown(false);
    }, 4000);
  }, []);

  return (
    <>
      {shown && (
        <div className={styles.toast}>
          <Checkmark />
          <div className={styles.toastContent}>
            {change === 'added' && <div>Supplement order confirmed</div>}
            {change === 'canceled' && (
              <div>Subscription{multipleSubscriptionsChanged ? 's' : ''} successfully canceled</div>
            )}
            {change === 'updated' && (
              <div>Subscription{multipleSubscriptionsChanged ? 's' : ''} successfully updated</div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

function filterAndSortSubcriptionsForType(subscriptions: BillingSubscription[], type: SKUType) {
  const skuPrefixes = skuPrefixesForType[type];
  const activeSupplementSubscriptions = subscriptions?.filter(
    (sub) =>
      sub.state === BillingSubscriptionState.ACTIVE &&
      skuPrefixes.some((prefix) => sub.subscriptionOption.sku.startsWith(prefix)),
  );
  // Sort by nextShipmentDate ascending
  return activeSupplementSubscriptions.sort((a: any, b: any) =>
    a.supplementShipmentDates.displayDate.localeCompare(b.supplementShipmentDates.displayDate),
  );
}

export interface SplashPageState {
  toast?: { success: boolean; change: ChangeType; multipleSubscriptionsChanged: boolean };
  refetch?: boolean;
}

export default function SplashPage() {
  const location = useLocation<SplashPageState>();
  const [showToast, setShowToast] = useState(false);
  const [toastChange, setToastChange] = useState<ChangeType | null>(null);
  const locationToastChange = location.state?.toast?.change ?? null;
  useEffect(() => {
    if (locationToastChange) {
      setShowToast(true);
      setToastChange(locationToastChange);
    }
  }, [locationToastChange]);

  const showUpsell = new URLSearchParams(window.location.search).get('show_upsell') === 'true';
  const history = useHistory();
  const [addClicked, setAddClicked] = useState(false);
  const refetchQuery = location.state?.refetch ?? false;
  const multipleSubscriptionsChanged = location.state?.toast?.multipleSubscriptionsChanged ?? false;
  const UpsellComponent = getFiGrowthBook().getFeatureValue<boolean>('ecom-supplements-4-sku', false)
    ? UpsellV2
    : Upsell;

  const { data, loading, refetch } = useQuery<gqlTypes.ECOMMERCE_getSupplementSubscriptionsQuery>(
    supplementsSubscriptionsQuery,
    { fetchPolicy: 'cache-and-network' },
  );

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    if (location.state) {
      // Safari retains state on page reload, so we need to clear it
      history.replace({
        ...location,
        state: null,
      });
    }
  }, [history, location]);

  useEffect(() => {
    if (refetchQuery) {
      refetch();
    }
  }, [refetch, refetchQuery]);

  if (loading) {
    return <Loading />;
  }

  const hasEverPurchasedSupplementSubscription = data?.currentUser.hasPurchasedSupplementSubscription;
  const hideFree = !!hasEverPurchasedSupplementSubscription;

  const supplementSubscriptions: BillingSubscription[] = data?.currentUser.billingAccount?.subscriptions ?? [];

  const handleAddSupplements = () => {
    events.addSupplementsTapped({});
    setAddClicked(true);
  };

  const componentToReturn = () => {
    function MainPage() {
      const pageName = 'Main Page';
      events.pageLoad({}, pageName);

      return (
        <>
          <div className={classNames(styles.fullWidthWrapper, styles.darkBackground)}>
            <SubscriptionAppBar
              title={'\u200B'} // Invisible title to allow the app bar to be the correct height
              backButtonExitsWebview={true}
              backButtonAnalyticsEvent={() => events.goBackFromPage({}, pageName)}
              closeButtonAnalyticsEvent={() => events.closePage({}, pageName)}
              noCloseButton={true}
              addButtonAction={handleAddSupplements}
              darkBackground
            />
            <div className={classNames(styles.subscriptionContainer, styles.darkBackground)}>
              <div className={styles.headerContainer}>
                <div className={styles.supplementsHeader}>Supplements</div>
                <h4>UPCOMING SHIPMENTS</h4>
              </div>
              {Object.values(SKUType).map((skuType) => {
                const sortedSubscriptions = filterAndSortSubcriptionsForType(supplementSubscriptions, skuType);
                return (
                  sortedSubscriptions.length > 0 && (
                    <>
                      <p key={skuType} className={styles.skuHeader}>
                        {skuType}
                      </p>
                      {sortedSubscriptions.map((subscription: any, idx: number) => (
                        <React.Fragment key={subscription.id}>
                          <SupplementPill
                            hasMultipleSubscriptions={sortedSubscriptions.length > 1}
                            subscription={subscription}
                            subscriptionDetails={subscription}
                            version={'informational'}
                            pendingPlan={
                              subscription.pendingChanges ? subscription.pendingChanges.subscriptionOption : null
                            }
                            key={subscription.id}
                          />
                        </React.Fragment>
                      ))}
                    </>
                  )
                );
              })}
              <div className={classNames(styles.buttonContainer, styles.darkBackground)}>
                <Button className={classNames(styles.button, styles.splashPageButton)} onClick={handleAddSupplements}>
                  <AddIcon />
                  {'  '}Add supplements
                </Button>
              </div>
            </div>

            {showToast && !!toastChange && (
              <Toast change={toastChange} multipleSubscriptionsChanged={multipleSubscriptionsChanged} />
            )}
          </div>
        </>
      );
    }

    if (showUpsell || !supplementSubscriptions.length) {
      return (
        <FeaturesReady>
          <UpsellComponent referrer="upsell" closeUpsell={() => setAddClicked(false)} hideFree={hideFree} />
        </FeaturesReady>
      );
    }

    if (addClicked) {
      return <UpsellComponent referrer="add" closeUpsell={() => setAddClicked(false)} hideFree={hideFree} />;
    }

    return <MainPage />;
  };

  return <>{componentToReturn()}</>;
}
