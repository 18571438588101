import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { usePurchaseSubscriptionContext } from '../PurchaseSubscription/context/PurchaseSubscriptionContext';
import { formattedCentsToDollars } from '../../lib/util/money';

function subscriptionOptionName(months: number) {
  if (months % 12 === 0) {
    const years = months / 12;
    return `${years} Year Membership`;
  }

  return `${months} Month Membership`;
}

export default function usePrepaidSubscriptionOption() {
  const { device, subscriptionOptions } = usePurchaseSubscriptionContext();

  return useMemo(() => {
    const prepaidSubscription = device.prepaidSubscription;

    const devicePreselectedPlanCode = prepaidSubscription?.planCode;
    if (!devicePreselectedPlanCode) {
      return null;
    }

    const subscriptionOption = subscriptionOptions.find((option) => option.sku === devicePreselectedPlanCode);
    if (!subscriptionOption) {
      return null;
    }

    const firstTermFormattedPrice = formattedCentsToDollars(
      prepaidSubscription.resellerPriceInCents ?? subscriptionOption.priceInCents,
    );

    const renewalPlanCode = device.prepaidSubscription?.renewalPlanCode;
    const renewalSubscriptionOption = renewalPlanCode
      ? subscriptionOptions.find((option) => option.sku === renewalPlanCode) ?? subscriptionOption
      : subscriptionOption;

    const renewalFormattedPrice = formattedCentsToDollars(renewalSubscriptionOption.priceInCents);

    const firstTermMonths = subscriptionOption.renewalMonths + (prepaidSubscription.freeMonths ?? 0);
    const nextBillingDate = DateTime.now().plus({ months: firstTermMonths }).toFormat('MM/dd/yyyy');

    return {
      subscriptionOptionSku: subscriptionOption.sku,
      subscriptionOptionName: subscriptionOptionName(firstTermMonths),
      formattedPrice: firstTermFormattedPrice,
      renewalSubscriptionOption,
      renewalFormattedPrice,
      nextBillingDate,
      orderSource: prepaidSubscription.orderSource,
    };
  }, [device.prepaidSubscription, subscriptionOptions]);
}
