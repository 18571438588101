import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AppPaths from '../../AppPaths';
import { useCartPricing } from '../../contexts/CartPricingContext';
import useHandlePurchase from '../../hooks/useHandlePurchase';
import * as types from '../../types';
import { usePurchaseSubscriptionContext } from '../PurchaseSubscription/context/PurchaseSubscriptionContext';

interface UseActivateSubscriptionProps {
  setError: (message: string) => void;
}

export default function useActivateSubscription({ setError }: UseActivateSubscriptionProps) {
  const history = useHistory();
  const cartPricing = useCartPricing();
  const emailAddress = useSelector((state: types.AppState) => state.session?.email);
  const { device } = usePurchaseSubscriptionContext();

  const onSuccess = useCallback(() => {
    history.replace(AppPaths.PrepaidActivation.Confirmation(device.moduleId));
  }, [history, device.moduleId]);

  const { handlePurchase, purchaseMutationLoading: mutationLoading } = useHandlePurchase({
    cartPricing,
    onError: setError,
    onSuccess,
  });

  const activateSubscription = useCallback(() => handlePurchase(emailAddress), [handlePurchase, emailAddress]);

  return { activateSubscription, loading: mutationLoading, onSuccess };
}
