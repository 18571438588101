import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  addMultipleSupplementSubscriptionsMutation,
  getMultipleSupplementSubscriptionsPricingQuery,
  supplementsSubscriptionsQuery,
} from '../../../../graphql-operations';
import * as types from '../../../../types';
import SubscriptionAppBar from '../../components/SubscriptionAppBar';
import PurchaseSubscriptionPaymentBody from '../../../PurchaseSubscription/PurchaseSubscriptionPayment/PurchaseSubscriptionPaymentBody';
import * as events from '../../../../lib/analytics/events';
import styles from '../../Collar/SubscriptionSplash/components/UpdatePayment/UpdatePayment.module.scss';
import AppPaths from '../../../../AppPaths';
import {
  getCartSKUs,
  getSKUForCartItem,
  PurchaseType,
  SKUType,
  SUPPLEMENT_BAG_IMAGES,
} from '../components/UpsellV2/UpsellV2';
import { getItemPrice } from '../components/InAppCheckoutModal/InAppCheckoutModal';
import { formattedCentsToDollars, getLoadingOrErrorElement } from '../../../../lib/util';
import classNames from 'classnames';
import productInfoStyles from '../../../Checkout/components/Supplements/ProductInfoV2/ProductInfoV2.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useCartMode } from '../../../../lib/cartModes';
import { priceCartForCheckout } from '../../../../lib/pricing';
import { CheckoutContextProvider } from '../../../../lib/CheckoutContext';
import { CartPricingProvider } from '../../../../contexts/CartPricingContext';
import { cartActions } from '../../../../reducers/storeCart';

const SUPPLEMENT_FIRST_BAG_DISCOUNT_COUPON_CODE = 'supplement-first-bag-discount';

function PurchaseConfirmation() {
  const location = useLocation();
  const { isEligibleForFirstBagDiscount, purchaseType, autoshipFrequency, selectedSKUType } = location.state as {
    isEligibleForFirstBagDiscount: boolean;
    purchaseType: PurchaseType;
    autoshipFrequency: number;
    selectedSKUType: SKUType;
  };

  const history = useHistory();
  const goToSupplementsHomeWithSuccess = (refetch: boolean) => {
    history.push({
      pathname: AppPaths.Supplements.Root,
      search: window.location.search,
      state: { toast: { success: true, change: 'added' }, refetch },
    });
  };
  const {
    data: subscriptionData,
    loading: subscriptionLoading,
    error: subscriptionError,
  } = useQuery<types.gqlTypes.ECOMMERCE_getSupplementSubscriptionsQuery>(supplementsSubscriptionsQuery, {
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: pricingData,
    loading: pricingLoading,
    error: pricingError,
  } = useQuery<
    types.gqlTypes.ECOMMERCE_getMultipleSupplementSubscriptionsPricing,
    types.gqlTypes.ECOMMERCE_getMultipleSupplementSubscriptionsPricingVariables
  >(getMultipleSupplementSubscriptionsPricingQuery, {
    variables: {
      input: {
        skusToAdd: getCartSKUs([
          {
            skuType: selectedSKUType,
            purchaseType,
            frequency: autoshipFrequency,
            eligibleForDiscount: isEligibleForFirstBagDiscount && purchaseType === PurchaseType.Autoship,
          },
        ]),
      },
    },
  });

  const [purchaseMutation, purchaseMutationState] = useMutation<
    types.gqlTypes.ECOMMERCE_addMultipleSupplementSubscriptions,
    types.gqlTypes.ECOMMERCE_addMultipleSupplementSubscriptionsVariables
  >(addMultipleSupplementSubscriptionsMutation, {
    onCompleted: (mutationData) => {
      const success = !!mutationData.addMultipleSupplementSubscriptions?.success;
      if (!success) {
        throw new Error(
          mutationData.addMultipleSupplementSubscriptions?.reasonForFailure ??
            'Unable to purchase subscription. Please contact customer support',
        );
      } else {
        events.supplements.purchaseSucceeded('in-app');
        goToSupplementsHomeWithSuccess(false);
      }
    },
  });
  const onPurchase = () => {
    purchaseMutation({
      variables: {
        input: {
          skusToAdd: getCartSKUs([
            {
              skuType: selectedSKUType,
              purchaseType,
              frequency: autoshipFrequency,
              eligibleForDiscount: isEligibleForFirstBagDiscount && purchaseType === PurchaseType.Autoship,
            },
          ]),
        },
      },
    });
  };

  const loadingOrErrorElement = getLoadingOrErrorElement(pricingLoading, pricingError);
  if (loadingOrErrorElement) {
    return loadingOrErrorElement;
  }
  const loadingOrErrorElementSubscriptions = getLoadingOrErrorElement(subscriptionLoading, subscriptionError);
  if (loadingOrErrorElementSubscriptions) {
    return loadingOrErrorElementSubscriptions;
  }

  const lastFour =
    subscriptionData?.currentUser.billingAccount?.billingInfo?.paymentInfo?.__typename === 'ObfuscatedCardInfo'
      ? subscriptionData.currentUser.billingAccount.billingInfo.paymentInfo.lastFour
      : null;
  const tax = formattedCentsToDollars(pricingData?.getMultipleSupplementSubscriptionsPricing?.taxInCents || 0);
  const totalPrice = formattedCentsToDollars(pricingData?.getMultipleSupplementSubscriptionsPricing?.totalInCents || 0);

  return (
    <div className={styles.purchaseConfirmationContainer}>
      <div className={productInfoStyles.orderSummaryContainer}>
        {lastFour && (
          <div className={productInfoStyles.orderSummaryTitle}>
            Your card on file ending in • • • • {lastFour} will be charged upon confirmation.
          </div>
        )}
        <div className={productInfoStyles.cartItem}>
          <div className={productInfoStyles.cartItemDetails}>
            <div className={productInfoStyles.cartItemImage}>
              <img src={SUPPLEMENT_BAG_IMAGES[selectedSKUType].url} alt={selectedSKUType} />
            </div>
            <div className={productInfoStyles.cartItemText}>
              <div className={productInfoStyles.cartItemTitle}>{selectedSKUType}</div>
              {purchaseType === PurchaseType.Autoship && (
                <div className={productInfoStyles.cartItemDescription}>
                  Auto ships every {autoshipFrequency === 1 ? 'week' : `${autoshipFrequency} weeks`}
                </div>
              )}
            </div>
          </div>
          <div className={productInfoStyles.cartItemPrice}>
            <div className={productInfoStyles.cartItemPriceValue}>${getItemPrice(purchaseType)}</div>
          </div>
        </div>
        {isEligibleForFirstBagDiscount && purchaseType === PurchaseType.Autoship && (
          <div className={productInfoStyles.promotionContainer}>
            <div className={productInfoStyles.promotionTitle}>First bag special promotion</div>
            <div className={productInfoStyles.promotionValue}>-$10</div>
          </div>
        )}
        <div className={productInfoStyles.taxContainer}>
          <div className={productInfoStyles.taxTitle}>Tax:</div>
          <div className={productInfoStyles.taxValue}>{tax}</div>
        </div>
        <div className={productInfoStyles.totalDueContainer}>
          <div className={productInfoStyles.totalDueTitle}>Total due today:</div>
          <div className={productInfoStyles.totalDueValue}>{totalPrice}</div>
        </div>
      </div>
      <div className={classNames(productInfoStyles.desktopCTAContainer, styles.purchaseConfirmationCTAContainer)}>
        <button
          className={classNames(productInfoStyles.confirmOrderButton)}
          disabled={!purchaseType || purchaseMutationState.loading}
          onClick={onPurchase}
        >
          Confirm order
        </button>
        <div className={styles.disclaimer}>
          By taping Confirm Order you accept the{' '}
          <a href="https://tryfi.com/tos" target="_blank" rel="noopener noreferrer">
            Terms & Conditions.
          </a>
        </div>
      </div>
    </div>
  );
}

function CheckoutAddPaymentContextProvider({ children }: { children: React.ReactNode }) {
  const { cartSelector, checkoutType } = useCartMode();
  const cart = useSelector(cartSelector);
  const fetchPricing = useCallback(() => priceCartForCheckout(cart, { checkoutType }), [cart, checkoutType]);

  return (
    <CheckoutContextProvider>
      <CartPricingProvider fetchPricing={fetchPricing}>{children}</CartPricingProvider>
    </CheckoutContextProvider>
  );
}

export default function CheckoutAddPayment() {
  const showApplePay = false; // TODO: re-enable this once we have a way to test and debug Apple Pay
  const [displayPurchaseConfirmation, setDisplayPurchaseConfirmation] = useState(false);

  const pageName = 'Supplements Checkout Add Payment';
  events.supplementsManagement.pageLoad({}, pageName);

  const location = useLocation();
  const dispatch = useDispatch();
  const { isEligibleForFirstBagDiscount, purchaseType, autoshipFrequency, selectedSKUType } = location.state as {
    isEligibleForFirstBagDiscount: boolean;
    purchaseType: PurchaseType;
    autoshipFrequency: number;
    selectedSKUType: SKUType;
  };
  const includeCouponCode = isEligibleForFirstBagDiscount && purchaseType === PurchaseType.Autoship;
  if (includeCouponCode) {
    dispatch(cartActions.addCoupon(SUPPLEMENT_FIRST_BAG_DISCOUNT_COUPON_CODE));
  }
  dispatch(
    cartActions.addSingleLineCartItem({
      sku: getSKUForCartItem({
        skuType: selectedSKUType,
        purchaseType,
        frequency: autoshipFrequency,
        eligibleForDiscount: includeCouponCode,
      }),
      quantity: 1,
    }),
  );

  return (
    <CheckoutAddPaymentContextProvider>
      <SubscriptionAppBar
        title="Check out"
        backButtonAnalyticsEvent={() => events.supplementsManagement.goBackFromPage({}, pageName)}
        closeButtonAnalyticsEvent={() => events.supplementsManagement.closePage({}, pageName)}
      />
      {!displayPurchaseConfirmation && (
        <div className={styles.billingInfo}>
          <PurchaseSubscriptionPaymentBody
            includeCancel={false}
            onSubmit={() => setDisplayPurchaseConfirmation(true)}
            showApplePay={showApplePay}
            actionText="Next"
            skipAccountCheck={true}
          />
        </div>
      )}
      {displayPurchaseConfirmation && <PurchaseConfirmation />}
    </CheckoutAddPaymentContextProvider>
  );
}
