import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Tracker } from '../../../../components/Impact/Tracker';
import * as events from '../../../../lib/analytics/events';
import CheckoutContext, { CheckoutContextProvider } from '../../../../lib/CheckoutContext';
import { logInternalError } from '../../../../lib/errors';
import SupplementsLocationState from '../../../../models/SupplementsLocationState';
import * as types from '../../../../types';
import OrderConfirmedBanner from '../../components/Supplements/OrderConfirmedBanner/OrderConfirmedBanner';
import styles from './Supplements.module.scss';
import { FeaturesReady } from '@growthbook/growthbook-react';
import { CartModeProvider, CartModes } from '../../../../lib/cartModes';
import { clearGlobalVariablesForConversionTracking } from '../../../../lib/analytics/analyticsUtils';
import ProductInfoV2 from '../../components/Supplements/ProductInfoV2/ProductInfoV2';

export default function Supplements(): JSX.Element {
  const dispatch = useDispatch();
  const session = useSelector((state: types.AppState) => state.session);
  const { cart, checkoutActions } = useContext(CheckoutContext);
  const location = useLocation<SupplementsLocationState>();
  const locationState = location?.state;
  const { orderedCart, orderedCartPricing, orderId } = locationState ?? {};

  const [hasTrackedPageLoad, setHasTrackedPageLoad] = useState(false);

  useEffect(() => {
    // Avoids tracking multiple times if re-rendered
    if (hasTrackedPageLoad) {
      return;
    }

    // Resetting the cart below triggers a re-render, so we need to make sure we don't do it multiple (infinity) times
    if (Object.keys(cart.cartItems).length === 0) {
      return;
    }

    // On mount: Track view, clear cart
    events.supplements.viewed();
    dispatch(checkoutActions.resetCart({ resetCoupon: true }));
    dispatch(checkoutActions.resetCheckout({}));
    setHasTrackedPageLoad(true);

    if (!orderId || !orderedCart) {
      logInternalError('Supplements: Missing orderId or orderedCart in state');
    }
  }, [cart.cartItems, checkoutActions, dispatch, hasTrackedPageLoad, orderId, orderedCart]);

  useEffect(() => {
    // On unmount: clear tracking variables
    return () => {
      clearGlobalVariablesForConversionTracking();
    };
  }, []);

  return (
    <>
      {orderId && orderedCart && (
        <Tracker orderId={orderId} cart={orderedCart} cartPricing={orderedCartPricing} session={session} />
      )}
      <OrderConfirmedBanner email={session?.email} />
      <hr className={styles.divider} />
      <FeaturesReady>
        <CartModeProvider mode={CartModes.default}>
          <CheckoutContextProvider>
            <ProductInfoV2
              originalCart={orderedCart}
              originalCartPricing={orderedCartPricing}
              originalOrderId={orderId}
            />
          </CheckoutContextProvider>
        </CartModeProvider>
      </FeaturesReady>
    </>
  );
}
