import * as types from '../../types';
import { centsToDollars } from './money';

export type RateUnit = 'month' | 'week' | 'day' | 'total';

interface SubscriptionRateVariant {
  unit: RateUnit;
  displayRate: string;
}

interface SubscriptionProduct {
  billingCadence: types.BillingCadence;
  displayedRate: string;
  priceInCents: number;
  renewalMonths: number;
}

const DAYS_IN_A_MONTH = 30;
const DAYS_IN_A_WEEK = 7;

export function getPriceForRateUnit(
  subscriptionProduct: SubscriptionProduct,
  rateUnit: RateUnit,
): SubscriptionRateVariant {
  let monthlyPriceInCents: number;
  if (subscriptionProduct.billingCadence === types.BillingCadence.Year) {
    monthlyPriceInCents = subscriptionProduct.priceInCents / (subscriptionProduct.renewalMonths * 12);
  } else {
    monthlyPriceInCents = subscriptionProduct.priceInCents / subscriptionProduct.renewalMonths;
  }

  let raw: number;
  switch (rateUnit) {
    case 'day':
      raw = Math.floor(monthlyPriceInCents / DAYS_IN_A_MONTH);
      // Daily
      return {
        unit: 'day',
        displayRate: displayValue(raw),
      };

    case 'week':
      // Weekly - round pennies to 0
      raw = Math.floor(((monthlyPriceInCents / DAYS_IN_A_MONTH) * DAYS_IN_A_WEEK) / 10) * 10;
      return {
        unit: 'week',
        displayRate: displayValue(raw),
      };

    case 'month':
    default:
      // Monthly
      raw = Math.floor(monthlyPriceInCents);
      return {
        unit: 'month',
        displayRate: subscriptionProduct.displayedRate,
      };
  }
}

function displayValue(value: number, fixed = 2): string {
  return `$${centsToDollars(value, fixed)}`;
}

const NANO_ELEVATED_COUPON_CODE_CENTS = 9900;

// One-off function to retrieve the *total* price of the nano subscription, including the activation fee
// Currently relies on the GB flag 'nano-elevated-promo-offer'.
export function getExperimentalTotalNanoPrice(subscriptionProduct: SubscriptionProduct): SubscriptionRateVariant {
  const displayRateValue = subscriptionProduct.priceInCents;

  const reducedPrice = displayRateValue - NANO_ELEVATED_COUPON_CODE_CENTS;

  return {
    unit: 'total',
    displayRate: displayValue(reducedPrice, 0),
  };
}
