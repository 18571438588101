import { BandSeries, SizeOption, IVariant, ColorOption, BandMaker } from '../types';
import { expectUnreachable } from './util';

export type BandWidth = '3/4"' | '1"';

// Only show the size options that are available for the F1 band series based on inventory.
export const f1BandSizeOptions = [SizeOption.Small, SizeOption.Medium, SizeOption.Large, SizeOption.XLarge] as const;
export type F1BandSizeOption = typeof f1BandSizeOptions[number];

export const f3BandSizeOptions = [
  SizeOption.XSmall,
  SizeOption.Small,
  SizeOption.Medium,
  SizeOption.Large,
  SizeOption.XLarge,
] as const;
export type F3BandSizeOption = typeof f3BandSizeOptions[number];

export interface SizeOptionProps {
  bandSeries: BandSeries;
  sizeOption: F1BandSizeOption | F3BandSizeOption;
  bandMaker?: BandMaker;
  color?: ColorOption;
}

export interface BandSizeDetails {
  dogList: string;
  sizeName: string;
  neckSize: string;
  bandWidth: BandWidth;
}

export function sortedSizeOptionsForVariants(variants: IVariant[]) {
  const sizeOptions = new Set(variants.map((variant) => variant.options.size));
  const sizeSortOrder = (sizeOption: SizeOption) => {
    if (sizeOption === SizeOption.XSmall) {
      return 0;
    } else if (sizeOption === SizeOption.Small) {
      return 1;
    } else if (sizeOption === SizeOption.Medium) {
      return 2;
    } else if (sizeOption === SizeOption.Large) {
      return 3;
    } else if (sizeOption === SizeOption.XLarge) {
      return 4;
    } else {
      expectUnreachable(sizeOption);
      throw new Error(`Unknown size option: ${sizeOption}`);
    }
  };

  return Array.from(sizeOptions).sort((a, b) => sizeSortOrder(a) - sizeSortOrder(b));
}

export function commonDetailsForSize(sizeOption: SizeOption): { dogList: string; sizeName: string } {
  if (sizeOption === SizeOption.XSmall) {
    return {
      dogList: 'Dachshund (miniature), Maltese, Pomeranian, Toy Poodle, Yorkshire Terrier, etc.',
      sizeName: 'X Small',
    };
  } else if (sizeOption === SizeOption.Small) {
    return {
      dogList:
        'Boston Terrier, Cairn Terrier, Cavalier King Charles, Dachshund, French Bulldog, Jack Russell Terrier, Mini Aussie, Pug, etc.',
      sizeName: 'Small',
    };
  } else if (sizeOption === SizeOption.Medium) {
    return {
      dogList: 'Aussie, Beagle, Border Collie, Corgi, English Bulldog, etc.',
      sizeName: 'Medium',
    };
  } else if (sizeOption === SizeOption.Large) {
    return {
      dogList:
        'Boxer, Doberman, German Shepherd, Golden Retriever, Labrador Retriever, Pit Bull, Siberian Husky, Standard Poodle, etc.',
      sizeName: 'Large',
    };
  } else if (sizeOption === SizeOption.XLarge) {
    return {
      dogList: 'Bloodhound, Great Dane, Great Pyrenees, Mastiff, Newfoundland, Rottweiler, etc.',
      sizeName: 'X Large',
    };
  } else {
    expectUnreachable(sizeOption);
    throw new Error(`Unknown size option: ${sizeOption}`);
  }
}

function sizeDetailsForF1SizeOption(sizeOption: F1BandSizeOption): BandSizeDetails {
  const commonDetails = commonDetailsForSize(sizeOption);

  if (sizeOption === SizeOption.Small) {
    return {
      ...commonDetails,
      neckSize: '11.5" - 13.5"',
      bandWidth: '1"',
    };
  } else if (sizeOption === SizeOption.Medium) {
    return {
      ...commonDetails,
      neckSize: '13" - 16.5"',
      bandWidth: '1"',
    };
  } else if (sizeOption === SizeOption.Large) {
    return {
      ...commonDetails,
      neckSize: '16" - 22.5"',
      bandWidth: '1"',
    };
  } else if (sizeOption === SizeOption.XLarge) {
    return {
      ...commonDetails,
      neckSize: '22" - 34.5"',
      bandWidth: '1"',
    };
  } else {
    expectUnreachable(sizeOption);
    throw new Error(`Unknown size option: ${sizeOption}`);
  }
}

function sizeDetailsForF3SizeOption(sizeOption: F3BandSizeOption): BandSizeDetails {
  const commonDetails = commonDetailsForSize(sizeOption);

  if (sizeOption === SizeOption.XSmall) {
    return {
      ...commonDetails,
      neckSize: '9.5" - 11"',
      bandWidth: '3/4"',
    };
  } else if (sizeOption === SizeOption.Small) {
    return {
      ...commonDetails,
      neckSize: '10.5" - 13"',
      bandWidth: '3/4"',
    };
  } else if (sizeOption === SizeOption.Medium) {
    return {
      ...commonDetails,
      neckSize: '12.5" - 16.5"',
      bandWidth: '1"',
    };
  } else if (sizeOption === SizeOption.Large) {
    return {
      ...commonDetails,
      neckSize: '15.5" - 22"',
      bandWidth: '1"',
    };
  } else if (sizeOption === SizeOption.XLarge) {
    return {
      ...commonDetails,
      neckSize: '20" - 31"',
      bandWidth: '1"',
    };
  } else {
    expectUnreachable(sizeOption);
    throw new Error(`Unknown size option: ${sizeOption}`);
  }
}

function sizeDetailsForMakersBands(sizeOption: SizeOption, colorOption: ColorOption): BandSizeDetails | null {
  const commonDetails = commonDetailsForSize(sizeOption);

  if (colorOption === ColorOption.ZeeDogPhantom) {
    if (sizeOption === SizeOption.Small) {
      return {
        ...commonDetails,
        neckSize: '10.5" - 13"',
        bandWidth: '3/4"',
      };
    } else if (sizeOption === SizeOption.Medium) {
      return {
        ...commonDetails,
        neckSize: '13" - 16.5"',
        bandWidth: '1"',
      };
    } else if (sizeOption === SizeOption.Large) {
      return {
        ...commonDetails,
        neckSize: '16" - 22"',
        bandWidth: '1"',
      };
    } else if (sizeOption === SizeOption.XLarge) {
      return {
        ...commonDetails,
        neckSize: '20" - 31"',
        bandWidth: '1"',
      };
    }
  } else if (colorOption === ColorOption.StuntPuppyGoDog || colorOption === ColorOption.StuntPuppyTealBiothane) {
    if (sizeOption === SizeOption.Small) {
      return {
        ...commonDetails,
        neckSize: '10.75" - 12.5"',
        bandWidth: '3/4"',
      };
    } else if (sizeOption === SizeOption.Medium) {
      return {
        ...commonDetails,
        neckSize: '12.5" - 15.5"',
        bandWidth: '1"',
      };
    } else if (sizeOption === SizeOption.Large) {
      return {
        ...commonDetails,
        neckSize: '15.5" - 20.5"',
        bandWidth: '1"',
      };
    } else if (sizeOption === SizeOption.XLarge) {
      return {
        ...commonDetails,
        neckSize: '19.5" - 30"',
        bandWidth: '1"',
      };
    }
  } else if (
    colorOption === ColorOption.StuntPuppyPinesBlue ||
    colorOption === ColorOption.StuntPuppyFishtailTeal ||
    colorOption === ColorOption.StuntPuppyFloraFrolic
  ) {
    if (sizeOption === SizeOption.Small) {
      return {
        ...commonDetails,
        neckSize: '10.75" - 12.5"',
        bandWidth: '3/4"',
      };
    } else if (sizeOption === SizeOption.Medium) {
      return {
        ...commonDetails,
        neckSize: '12.5" - 15.5"',
        bandWidth: '1"',
      };
    } else if (sizeOption === SizeOption.Large) {
      return {
        ...commonDetails,
        neckSize: '15.5" - 20.5"',
        bandWidth: '1"',
      };
    } else if (sizeOption === SizeOption.XLarge) {
      return {
        ...commonDetails,
        neckSize: '19.5" - 30"',
        bandWidth: '1"',
      };
    }
  } else if (colorOption === ColorOption.MimiGreenRedBiothane) {
    if (sizeOption === SizeOption.Small) {
      return {
        ...commonDetails,
        neckSize: '12" - 13"',
        bandWidth: '3/4"',
      };
    } else if (sizeOption === SizeOption.Medium) {
      return {
        ...commonDetails,
        neckSize: '13" - 15"',
        bandWidth: '1"',
      };
    } else if (sizeOption === SizeOption.Large) {
      return {
        ...commonDetails,
        neckSize: '16" - 22"',
        bandWidth: '1"',
      };
    } else if (sizeOption === SizeOption.XLarge) {
      return {
        ...commonDetails,
        neckSize: '22" - 26"',
        bandWidth: '1"',
      };
    }
  } else if (colorOption === ColorOption.MimiGreenPinkReflective) {
    if (sizeOption === SizeOption.Small) {
      return {
        ...commonDetails,
        neckSize: '12" - 13"',
        bandWidth: '3/4"',
      };
    } else if (sizeOption === SizeOption.Medium) {
      return {
        ...commonDetails,
        neckSize: '13" - 15"',
        bandWidth: '1"',
      };
    } else if (sizeOption === SizeOption.Large) {
      return {
        ...commonDetails,
        neckSize: '16" - 22"',
        bandWidth: '1"',
      };
    } else if (sizeOption === SizeOption.XLarge) {
      return {
        ...commonDetails,
        neckSize: '22" - 26"',
        bandWidth: '1"',
      };
    }
  } else if (colorOption === ColorOption.RopeHoundsMountainTop || colorOption === ColorOption.RopeHoundsBubblegumPop) {
    if (sizeOption === SizeOption.Small) {
      return {
        ...commonDetails,
        neckSize: '12" - 15"',
        bandWidth: '1"',
      };
    } else if (sizeOption === SizeOption.Medium) {
      return {
        ...commonDetails,
        neckSize: '15" - 20"',
        bandWidth: '1"',
      };
    } else if (sizeOption === SizeOption.Large) {
      return {
        ...commonDetails,
        neckSize: '17" - 24"',
        bandWidth: '1"',
      };
    }
  } else if (
    colorOption === ColorOption.LandsharkSupplyBlackBlue ||
    colorOption === ColorOption.LandsharkSupplyBlackRed
  ) {
    if (sizeOption === SizeOption.Small) {
      return {
        ...commonDetails,
        neckSize: '13" - 16"',
        bandWidth: '1"',
      };
    } else if (sizeOption === SizeOption.Medium) {
      return {
        ...commonDetails,
        neckSize: '15" - 19"',
        bandWidth: '1"',
      };
    } else if (sizeOption === SizeOption.Large) {
      return {
        ...commonDetails,
        neckSize: '17" - 23"',
        bandWidth: '1"',
      };
    } else if (sizeOption === SizeOption.XLarge) {
      return {
        ...commonDetails,
        neckSize: '19" - 27"',
        bandWidth: '1"',
      };
    }
  }

  // For display purposes, fallback to default F3 sizing
  return null;
}

export function sizeDetailsForSizeOption(props: SizeOptionProps) {
  if (props.bandMaker !== BandMaker.Fi && props.color) {
    return sizeDetailsForMakersBands(props.sizeOption, props.color);
  }

  if (props.bandSeries === BandSeries.F1) {
    return sizeDetailsForF1SizeOption(props.sizeOption as F1BandSizeOption);
  } else if (props.bandSeries === BandSeries.F3) {
    return sizeDetailsForF3SizeOption(props.sizeOption);
  } else {
    expectUnreachable(props.bandSeries);
    throw new Error(`Unknown band series: ${props}`);
  }
}
