import React, { useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { supplementsManagement as events } from '../../../lib/analytics/events';
import { BillingSubscription, SubscriptionOption } from './SplashPage';
import styles from '../styles/SubscriptionContainer.module.scss';
import SubscriptionAppBar from '../components/SubscriptionAppBar';
import * as types from '../../../types';
import ManageShipmentActions from './components/ManageShipmentActions';
import ManageSubscriptionActions from './components/ManageSubscriptionActions';
import { DateTime } from 'luxon';
import { sizeSortOrder } from '../../Checkout/components/Supplements/helpers';
import ErrorScreen from '../components/ErrorScreen/ErrorScreen';
import AppPaths from '../../../AppPaths';

interface ManageSubscriptionState {
  hasMultipleSubscriptions: boolean;
  subscription: BillingSubscription;
  pendingSubscription: SubscriptionOption;
}

export default function ManageSubscription() {
  const eventPageName = 'In-App Supplements > Manage';
  events.pageLoad({}, eventPageName);

  const location = useLocation<ManageSubscriptionState>();
  const { hasMultipleSubscriptions, subscription, pendingSubscription } = location.state;
  const currentSubscriptionOption = pendingSubscription || subscription.subscriptionOption;
  const pageName = `Manage ${currentSubscriptionOption.inAppManagementName}`;
  const history = useHistory();
  const [showCancelButton, setShowCancelButton] = useState(false);

  // If there are pending changes (meaning the user has updated this subscription previously), we need to get more
  // information about the future plan so we can display it to the user. Behind the scenes, in Recurly, their plan will only be updated
  // on their next bill date (to avoid shipping a new bag of supplements prematurely), but we make an immediate change to the next bill
  // date, which appears in the UI, so it should appear as though the plan change has also happened immediately.
  const allProducts = useSelector((state: types.AppState) => state.config.products);
  // If there is a pending change on the subscription, filter supplement options based on the future plan.
  const supplementProductOptions = useMemo((): types.ISupplementSubscriptionProduct[] => {
    const unsortedSupplements = allProducts.filter(
      (p) => p.category === types.ProductCategory.SUPPLEMENT && p.display,
    ) as types.ISupplementSubscriptionProduct[];
    // Sort items so they appear in product selector in a logical order.
    return unsortedSupplements.sort(
      (a, b) => sizeSortOrder(b.recommendedForDogSize) - sizeSortOrder(a.recommendedForDogSize),
    );
  }, [allProducts]);

  if (!subscription) {
    return <ErrorScreen />;
  }

  function descriptionText() {
    switch (subscription.nextSupplementShipmentDetails?.status) {
      case 'Scheduled':
        return `Edit by ${DateTime.fromISO(subscription.supplementShipmentDates!.displayDate)
          .minus({ minutes: 1 })
          .toFormat('h:mm a ZZZZ')} on ${DateTime.fromISO(subscription.supplementShipmentDates!.displayDate)
          .minus({ minutes: 1 })
          .toFormat('cccc, LLL d')}`;
      case 'Shipment pending':
        return 'Shipment pending. Any updates will affect your next shipment.';
      case 'On its way':
        return 'On its way. Any updates will affect your next shipment.';
      default:
        return '';
    }
  }

  // Don't show deterrent to users already on least frequent cadence.
  const showChangeFrequencyDeterrent = currentSubscriptionOption.weeksBetweenShipments !== 12;
  const handleCancel = () => {
    events.cancelSubscription({ subscriptionId: subscription.id });
    history.push({
      pathname: AppPaths.Supplements.SkipShipment(subscription.id),
      state: {
        subscription,
        showChangeFrequencyDeterrent,
        currentSubscriptionOption,
        subscriptionOptions: supplementProductOptions,
      },
      search: window.location.search,
    });
  };

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          title={pageName}
          backButtonAnalyticsEvent={() => events.goBackFromPage({ subscriptionId: subscription.id }, eventPageName)}
          closeButtonAnalyticsEvent={() => events.closePage({ subscriptionId: subscription.id }, eventPageName)}
          noCloseButton
          moreButtonAction={() => setShowCancelButton(true)}
        />
        {showCancelButton && (
          <div className={styles.cancelButtonContainer} onClick={() => setShowCancelButton(false)}>
            <button className={styles.cancelButton} onClick={handleCancel}>
              Cancel subscription
            </button>
          </div>
        )}
        <div className={styles.subscriptionContainer}>
          <div className={styles.headerContainer}>
            <h1>Your upcoming shipment</h1>
            <p className={styles.description}>{descriptionText()}</p>
          </div>
          <ManageShipmentActions />

          <h1 className={styles.yourSubscriptionHeader}>Your subscription</h1>
          <ManageSubscriptionActions
            hasMultipleSubscriptions={hasMultipleSubscriptions}
            subscription={subscription}
            currentSubscriptionOption={currentSubscriptionOption}
            subscriptionOptions={supplementProductOptions}
          />
        </div>
      </div>
    </>
  );
}
